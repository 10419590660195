<template>
  <div class="mentionsLegales" >
    <div class="container">
      <div class="row text-center mt-4">
        <div class="col-12">
          <h2 class="help-title">Mentions légales</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            Ce site est édité par :
          </p>
          <strong> Dénomination sociale : </strong> <span><a href="https://geegz.fr" target="_blank">Geegz</a>, sous la commande du Groupe Audiens, 448 323 758, 74 rue Jean Bleuze, 92170 Vanves </span><br>
          <strong> RCS : </strong> <span>821 208 741 R.C.S Paris</span><br>
          <strong> Représentant légal</strong><span> Guillaume Guerdoux </span><br>
          <strong> Activité : </strong> <span>Programmation informatique, création et gestion d'une plateforme d'intermédiation </span><br>
          <strong> Adresse du siège : </strong> <span>36 rue Croix des Petits Champs, 75001 Paris </span><br>
          <strong> Directeur de publication : </strong> <span>Isabelle Thirion, Directrice Développement social et adhérents individuels, isabelle.thirion@audiens.org</span><br>
          <strong> Hébergeur : </strong> <span>OVH SAS, 424 761 419 R.C.S Lille, 2 rue Kellermann - 59100 Roubaix - France</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MentionsLegales',
}
</script>
